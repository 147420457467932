import { useQuery } from "react-query";
import { PotashReportingApi, SubscriptionsResponse } from "../../..";

export const useGetMailingListBySite = (
  locationId: string,
  reportTypeID: number,
  siteId: string
) => {
  const { data, isLoading, isError, isSuccess } = useQuery<SubscriptionsResponse>({
    queryKey: ["report-subscription", "by-site", locationId, reportTypeID],
    queryFn: () => PotashReportingApi.Subscriptions.get(siteId, locationId, reportTypeID),
    enabled: reportTypeID > 0 && Boolean(locationId && siteId)
  });

  return {
    siteUsers: data ? data.data : [],
    loading: isLoading,
    isError,
    isSuccess
  };
};

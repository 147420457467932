import { useQuery } from "react-query";
import { ApproverPermissionResponse, PotashReportingApi } from "../../..";

export const useGetApproverPermission = (
  locationId: string,
  reportTypeId: number,
  userGuid: string
) => {
  const { data, isLoading, isError, isSuccess } = useQuery<ApproverPermissionResponse>({
    queryKey: ["approver-permission", locationId, userGuid],
    queryFn: () => PotashReportingApi.ApproverPermissions.get(locationId, reportTypeId, userGuid),
    enabled: Boolean(locationId && userGuid) && reportTypeId > 0
  });

  return {
    permission: data?.data,
    loading: isLoading,
    isError,
    isSuccess
  };
};

import { useQuery } from "react-query";
import { PotashReportingApi, ReportStatusResponse } from "../../..";

export const useGetReportStatus = (
  locationId: string,
  reportTypeId: number,
  startDate: string,
  userGuid: string
) => {
  const { data, isLoading, isError, isSuccess } = useQuery<ReportStatusResponse>({
    queryKey: ["report-status", locationId, startDate],
    queryFn: () =>
      PotashReportingApi.ReportComments.getFinalizeReportStatus(
        locationId,
        reportTypeId,
        startDate,
        userGuid
      ),
    enabled: Boolean(locationId && userGuid) && reportTypeId > 0
  });

  return {
    status: data?.data,
    loading: isLoading,
    isError,
    isSuccess
  };
};

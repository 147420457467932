import { useQuery } from "react-query";
import { PotashReportingApi, UploadedFileResponse } from "../../..";

export const useGetOtherMonthFileDetails = (
  locationId: string,
  reportTypeId: number,
  sectionType: string,
  startDate: string,
  monthNumber: number,
  isNextMonth: boolean
) => {
  const { data, isLoading, isError, isSuccess } = useQuery<UploadedFileResponse>({
    queryKey: ["uploaded-other-file-details", locationId, sectionType, startDate, monthNumber],
    queryFn: () =>
      PotashReportingApi.HrTemplates.getUploadedFileDetails(
        locationId,
        reportTypeId,
        sectionType,
        startDate,
        monthNumber
      ),
    enabled: Boolean(locationId) && reportTypeId > 0 && isNextMonth
  });

  return {
    otherFileDetails: data?.data,
    loading: isLoading,
    isError,
    isSuccess
  };
};

import { FC } from "react";
import { Box, Fab } from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

const Footer: FC = () => {
  const openMail = () => {
    window.location.href =
      "mailto: List-Nutrien-IT-KOperations@nutrien.com;?subject= PotashReportingIssue";
  };

  return (
    <Box>
      <Fab
        color="primary"
        aria-label="info"
        onClick={openMail}
        size="small"
        title="Problems with this site can be directed to Support Team"
        sx={{
          position: "fixed",
          bottom: 10,
          right: 10
        }}
      >
        <QuestionMarkIcon />
      </Fab>
    </Box>
  );
};

export default Footer;

import { useMutation, useQueryClient } from "react-query";
import { PotashReportingApi, ReportComment, CommentsListResponse } from "../../..";

export const useUpdateReportComment = (locationId: string) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(
    (reportComment: ReportComment) => PotashReportingApi.ReportComments.update(reportComment),
    {
      onSuccess: (reportComment) => {
        queryClient.setQueryData<CommentsListResponse | undefined>(
          ["report-comment", locationId],
          (currentState: CommentsListResponse | undefined) => {
            if (!currentState) return undefined;

            const data = [...currentState.data, reportComment.data];

            return {
              ...currentState,
              data: data
            };
          }
        );
      }
    }
  );

  return {
    comment: data,
    updateReportComment: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};

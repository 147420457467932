import { MutationOptions, useMutation } from "react-query";
import { PotashReportingApi } from "../../../apis";
import { extractHttpErrorMessage } from "../../utils/http-error";
import { HTTPError, PresignedUrlResponse } from "../../../types";

export const useDownloadTemplate = (sectionType: string) => {
  const mutationOptions: MutationOptions<PresignedUrlResponse, HTTPError, string> = {
    onError: extractHttpErrorMessage,
    mutationFn: (locationId: string) =>
      PotashReportingApi.HrTemplates.downloadTemplate(locationId, sectionType)
  };

  const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation(mutationOptions);

  return {
    downloadTemplate: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    error: error?.message
  };
};

import { useMutation, useQueryClient } from "react-query";
import { PotashReportingApi, ReportSubscription, ReportSubscriptionsResponse } from "../../..";

export const useRemoveUserFromMailingList = (locationId: string) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(
    (reportSubscription: ReportSubscription) =>
      PotashReportingApi.ReportSubscriptions.delete(locationId, reportSubscription),
    {
      onSuccess: (_data, reportSubscription) => {
        queryClient.setQueryData<ReportSubscriptionsResponse | undefined>(
          ["report-subscription", locationId, reportSubscription.reportTypeId],
          (currentState: ReportSubscriptionsResponse | undefined) => {
            if (!currentState) return undefined;

            // Delete
            const newEntities = currentState.data.filter((state) => {
              return (
                state.email !== reportSubscription.email &&
                state.reportTypeId === reportSubscription.reportTypeId
              );
            });

            return {
              ...currentState,
              data: [...newEntities]
            };
          }
        );
      }
    }
  );

  return {
    subscription: data,
    loading: isLoading,
    isError,
    isSuccess,
    deleteSubscription: mutateAsync
  };
};

import { useMutation, useQueryClient } from "react-query";
import { PotashReportingApi, ApproverPermission, ApproverPermissionResponse } from "../../..";

export const useUpdateApproverPermissions = (locationId: string) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(
    (permission: ApproverPermission) =>
      PotashReportingApi.ApproverPermissions.update(locationId, permission),
    {
      onSuccess: (permission) => {
        queryClient.setQueryData<ApproverPermissionResponse | undefined>(
          ["approver-permission", locationId],
          (currentState: ApproverPermissionResponse | undefined) => {
            if (!currentState) return undefined;

            const data = permission.data;

            return {
              ...currentState,
              data: data
            };
          }
        );
      }
    }
  );

  return {
    permission: data,
    updateApproverPermission: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};

import { Application } from "@nutrien-operations/config";

export const basePath = "/potash-reporting";

const permissions = {
  public: "085032e2-9050-4c15-ade5-5f4269391f94",
  manageReports: "6f04842a-ff21-4f25-863e-34f135d91d40",
  mineDelayAdmin: "843850b9-5e51-47f2-8910-9b5393eea286",
  mineDelayReports: "f32225fa-fe85-4685-84c9-0b3d655a6519",
  reportDistribution: "f6391ba5-543e-429b-b92c-5fde5cb54eed",
  submitData: "ed47a3d6-0388-4a3a-bb95-cb582b93ff69",
  weeklyHighlightReport: "1bd6293f-48d8-40e1-9bf1-64956d13ac2f",
  configureApplication: "222c8af6-efe6-4a67-aae5-7179c384f395"
};

const applicationConfig: Application = {
  applicationId: "7a0e1ff3-4def-426c-903a-fadec9cc4a0a",
  name: "@nutrien-operations/potash-reporting",
  displayName: "Potash Reporting",
  icon: "layers",
  path: {
    value: basePath
  },
  order: 1,
  permissions,
  menu: {
    label: "Potash Reporting",
    path: "/",
    icon: "layers",
    children: [
      {
        icon: "home",
        label: "Home",
        path: "/",
        permission: permissions.public
      },
      {
        icon: "file-warning",
        label: "Downtime Reporting",
        path: "/downtime-reports",
        permission: permissions.public
      },
      {
        icon: "users",
        label: "Report Distribution",
        path: "/report-distribution/corporate-mailing-list",
        defaultCollapsed: true,
        children: [
          {
            label: "Corporate Mailing List",
            path: "/report-distribution/corporate-mailing-list",
            permission: permissions.reportDistribution
          }
        ],
        permission: permissions.reportDistribution
      },
      {
        icon: "layers",
        label: "Manage Reports",
        path: "/report-manager/mailing-list",
        defaultCollapsed: true,
        children: [
          {
            label: "Mailing List",
            path: "/report-manager/mailing-list",
            permission: permissions.manageReports
          },
          {
            label: "Manage Editors",
            path: "/report-manager/manage-editors",
            permission: permissions.manageReports
          },
          {
            label: "Manage Approvers",
            path: "/report-manager/manage-approvers",
            permission: permissions.manageReports
          }
        ],
        permission: permissions.manageReports
      },
      {
        icon: "edit",
        label: "Submit Data",
        path: "/submit-data/weekly-highlight-report",
        defaultCollapsed: true,
        children: [
          {
            label: "Weekly Highlight Report",
            path: "/submit-data/weekly-highlight-report",
            permission: permissions.submitData
          }
        ],
        permission: permissions.submitData
      },
      {
        icon: "layers",
        label: "Weekly Highlight Report",
        path: "/weekly-highlight-report",
        permission: permissions.weeklyHighlightReport
      },
      {
        icon: "layers",
        label: "Mine Delay Reports",
        path: "/mine-delay-reports/mine-delay-KPIs",
        defaultCollapsed: true,
        children: [
          {
            label: "Mine Delay KPI Report",
            path: "/mine-delay-reports/mine-delay-KPIs",
            permission: permissions.mineDelayReports
          }
        ],
        permission: permissions.mineDelayReports
      },
      {
        label: "Admin",
        path: "/admin",
        permission: permissions.configureApplication
      }
    ]
  }
};

export default applicationConfig;

import { useMutation, useQueryClient } from "react-query";
import { PotashReportingApi, SiteReportSubscription, SubscriptionsResponse } from "../../..";

export const useRemoveUserFromSiteMailingList = (locationId: string) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(
    (subscription: SiteReportSubscription) =>
      PotashReportingApi.Subscriptions.delete(locationId, subscription),
    {
      onSuccess: (_data, subscription) => {
        queryClient.setQueryData<SubscriptionsResponse | undefined>(
          ["report-subscription", "by-site", locationId, subscription.reportTypeId],
          (currentState: SubscriptionsResponse | undefined) => {
            if (!currentState) return undefined;

            // Delete
            const newEntities = currentState.data.filter((state) => {
              return (
                state.email !== subscription.email &&
                state.reportTypeId === subscription.reportTypeId
              );
            });

            return {
              ...currentState,
              data: [...newEntities]
            };
          }
        );
      }
    }
  );

  return {
    subscription: data,
    loading: isLoading,
    isError,
    isSuccess,
    deleteSubscription: mutateAsync
  };
};

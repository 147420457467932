import { UseQueryOptions, useQuery } from "react-query";
import { HTTPError, LocationListResponse } from "../../../types";
import { CommonDataApi } from "../../..";
import { locationsOfApplication } from "./query-cache";

/**
 * Get a list of locations for a specific application.
 */
export const useGetLocationsByApplicationId = (applicationId: string) => {
  const queryOptions: UseQueryOptions<LocationListResponse, HTTPError> = {
    queryFn: () => CommonDataApi.Locations.getByApplicationId(applicationId),
    queryKey: locationsOfApplication(applicationId),
    enabled: !!applicationId
  };

  const { data, isLoading, isError, isSuccess } = useQuery(queryOptions);

  return {
    locations: data ? data.data : [],
    loading: isLoading,
    isError,
    isSuccess
  };
};

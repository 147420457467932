import { useMutation, useQueryClient } from "react-query";
import { PotashReportingApi } from "../../../apis";
import { ExistingFileResponse, UploadedFile } from "../../../types";

export const useUploadHrTemplateFile = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(
    (uploadFile: UploadedFile) => PotashReportingApi.HrTemplates.uploadTemplateFile(uploadFile),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(["uploaded-file-details"]);
        queryClient.invalidateQueries(["uploaded-other-file-details"]);
        queryClient.setQueryData<ExistingFileResponse | undefined>(
          ["upload-hr-template"],
          (currentState: ExistingFileResponse | undefined) => {
            if (!currentState) return undefined;

            const data = response.data;

            return {
              ...currentState,
              data: data
            };
          }
        );
      }
    }
  );

  return {
    existingFile: data,
    uploadTemplateFile: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError
  };
};

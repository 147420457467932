import { useMutation, useQueryClient } from "react-query";
import { PotashReportingApi, SiteReportSubscription, SubscriptionsResponse } from "../../..";

export const useAddUsersToSiteMailingList = (locationId: string) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(
    (subscription: SiteReportSubscription) => PotashReportingApi.Subscriptions.add(subscription),
    {
      onSuccess: (subscription) => {
        queryClient.setQueryData<SubscriptionsResponse | undefined>(
          ["report-subscription", "by-site", locationId, subscription.data.reportTypeId],
          (currentState: SubscriptionsResponse | undefined) => {
            if (!currentState) return undefined;

            let data = [...currentState.data, subscription.data];
            data = [...data].sort((a, b) => a.email.localeCompare(b.email));

            return {
              ...currentState,
              data: data
            };
          }
        );
      }
    }
  );

  return {
    subscription: data,
    addSubscriptions: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};

import { useQuery } from "react-query";
import { CommonDataApi, PeopleListResponse } from "../../..";

export const useGetPeopleByLocation = (applicationId, siteId, selectedLocationId) => {
  const { data, isLoading, isError, isSuccess } = useQuery<PeopleListResponse>(
    ["users", "by-location", selectedLocationId],
    () => CommonDataApi.People.getByLocation(applicationId, siteId, selectedLocationId),
    {
      enabled: Boolean(selectedLocationId && siteId)
    }
  );

  return {
    members: data?.data,
    loading: isLoading,
    isError,
    isSuccess
  };
};

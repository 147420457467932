import { useMutation } from "react-query";
import { PotashReportingApi } from "../../..";

export const useMoveFileToDrive = (locationId: string) => {
  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(
    (uploadedDataId: number) =>
      PotashReportingApi.HrTemplates.moveFileToDrive(locationId, uploadedDataId)
  );

  return {
    status: data,
    moveFileToDrive: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError
  };
};

import { useQuery } from "react-query";
import { PotashReportingApi, CommentDocumentResponse } from "../../..";

export const useGetIncidentRecords = (locationId: string, startDate: string) => {
  const { data, isLoading, isError, isSuccess } = useQuery<CommentDocumentResponse>({
    queryKey: ["incident-records", locationId, startDate],
    queryFn: () => PotashReportingApi.ReportComments.getIncidentRecords(locationId, startDate),
    enabled: Boolean(locationId && startDate)
  });

  return {
    incidentRecords: data?.data,
    loading: isLoading,
    isError,
    isSuccess
  };
};

import { useQuery } from "react-query";
import { PotashReportingApi, SectionResponse } from "../../..";

export const useGetSectionsBySite = (locationId: string, reportTypeID: number) => {
  const { data, isLoading, isError, isSuccess } = useQuery<SectionResponse>({
    queryKey: ["sections", "by-site", locationId, reportTypeID],
    queryFn: () => PotashReportingApi.Sections.getSections(locationId, reportTypeID),
    enabled: reportTypeID > 0 && Boolean(locationId)
  });

  return {
    subSections: data ? data.data : [],
    loading: isLoading,
    isError,
    isSuccess
  };
};

import { useMutation, useQueryClient } from "react-query";
import { PotashReportingApi, TabPermission } from "../../..";

export const useAddUserPermissions = (locationId: string, userId: string) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(
    (tabPermissions: TabPermission[]) => PotashReportingApi.Tabs.add(tabPermissions, userId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tab-permission", userId, locationId]);
      }
    }
  );

  return {
    permissions: data,
    addPermissions: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};

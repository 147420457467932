import { useMutation, useQueryClient } from "react-query";
import { PotashReportingApi, ReportApprovalResponse } from "../../..";

export const useUpdateReportApprovalStatus = (
  locationId: string,
  reportTypeId: number,
  startDate: string
) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(
    () => PotashReportingApi.ReportComments.finalizeReport(locationId, reportTypeId, startDate),
    {
      onSuccess: (approvalStatus) => {
        queryClient.invalidateQueries(["report-status"]);
        queryClient.setQueryData<ReportApprovalResponse | undefined>(
          ["report-approval-status"],
          (currentState: ReportApprovalResponse | undefined) => {
            if (!currentState) return undefined;

            const data = approvalStatus.data;

            return {
              ...currentState,
              data: data
            };
          }
        );
      }
    }
  );

  return {
    approvalStatus: data,
    updateReportApprovalStatus: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};

import { useQuery } from "react-query";
import { PotashReportingApi, TabResponse } from "../../..";

export const useGetTabsBySite = (locationId: string, reportTypeID: number) => {
  const { data, isLoading, isError, isSuccess } = useQuery<TabResponse>({
    queryKey: ["tabs", "by-site", locationId, reportTypeID],
    queryFn: () => PotashReportingApi.Tabs.getTabs(locationId, reportTypeID),
    enabled: reportTypeID > 0 && Boolean(locationId)
  });

  return {
    tabs: data ? data.data : [],
    loading: isLoading,
    isError,
    isSuccess
  };
};

import React from "react";
import { useSelectedLocation } from "@packages/service-api";
import { MessageBox } from "./MessageBox/MessageBox";

type Props = {
  applicationId: string;
  children: React.ReactNode;
};

export const EnsureLocationSelected: React.FC<Props> = ({ applicationId, children }) => {
  const { selectedLocation, loading } = useSelectedLocation(applicationId);

  if (!loading && !selectedLocation) {
    return <MessageBox>Please select a location to continue</MessageBox>;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

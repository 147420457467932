import { useQuery } from "react-query";
import { PotashReportingApi, PotashReportsResponse } from "../../..";

export const useGetReports = (locationId: string) => {
  const { data, isLoading, isError, isSuccess } = useQuery<PotashReportsResponse>({
    queryKey: ["reports", "list"],
    queryFn: () => PotashReportingApi.Reports.getPotashReports(locationId),
    enabled: !!locationId
  });
  return {
    reports: data?.data ? data.data : [],
    loading: isLoading,
    isError,
    isSuccess
  };
};

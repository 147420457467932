import { useMutation, useQueryClient } from "react-query";
import { PotashReportingApi, ReportApprovalResponse } from "../../..";

export const useUpdateReportInstanceApproval = (
  locationId: string,
  reportTypeId: number,
  startDate: string,
  notifyEmail: string
) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(
    () =>
      PotashReportingApi.ReportComments.approveReportAndNotify(
        locationId,
        reportTypeId,
        startDate,
        notifyEmail
      ),
    {
      onSuccess: (approvalStatus) => {
        queryClient.invalidateQueries(["report-status"]);
        queryClient.setQueryData<ReportApprovalResponse | undefined>(
          ["report-approval-and-notify"],
          (currentState: ReportApprovalResponse | undefined) => {
            if (!currentState) return undefined;

            const data = approvalStatus.data;

            return {
              ...currentState,
              data: data
            };
          }
        );
      }
    }
  );

  return {
    approvalStatus: data,
    updateReportApprovalAndNotify: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};

import { useMutation, useQueryClient } from "react-query";
import { PotashReportingApi, ReportSubscription, ReportSubscriptionsResponse } from "../../..";

export const useAddUsersToMailingList = (locationId: string) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(
    (reportSubscription: ReportSubscription) =>
      PotashReportingApi.ReportSubscriptions.add(reportSubscription, locationId),
    {
      onSuccess: (reportSubscription) => {
        queryClient.setQueryData<ReportSubscriptionsResponse | undefined>(
          ["report-subscription", locationId, reportSubscription.data.reportTypeId],
          (currentState: ReportSubscriptionsResponse | undefined) => {
            if (!currentState) return undefined;

            let data = [...currentState.data, reportSubscription.data];
            data = [...data].sort((a, b) => a.email.localeCompare(b.email));

            return {
              ...currentState,
              data: data
            };
          }
        );
      }
    }
  );

  return {
    subscription: data,
    addSubscriptions: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};

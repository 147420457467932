import { useMutation, useQueryClient } from "react-query";
import { PotashReportingApi } from "../../..";
import {
  PotashReportingSite,
  PotashReportingSiteResponse
} from "../../../types/potash-reporting/sites";

export const useUpdateLTIOverrides = (locationId: string) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(
    (site: PotashReportingSite) => PotashReportingApi.ReportComments.updateLTIOverrides(site),
    {
      onSuccess: (site) => {
        queryClient.setQueryData<PotashReportingSiteResponse | undefined>(
          ["LTI-overrides", locationId],
          (currentState: PotashReportingSiteResponse | undefined) => {
            if (!currentState) return undefined;

            const data = site.data;

            return {
              ...currentState,
              data: data
            };
          }
        );
      }
    }
  );

  return {
    site: data,
    updateLTIOverride: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};

import { useQuery } from "react-query";
import { PotashReportingApi } from "../../..";
import { PotashReportingSiteResponse } from "../../../types/potash-reporting/sites";

export const useGetLTIOverrides = (locationId: string) => {
  const { data, isLoading, isError, isSuccess } = useQuery<PotashReportingSiteResponse>({
    queryKey: ["LTI-overrides", locationId],
    queryFn: () => PotashReportingApi.ReportComments.getLTIOverrides(locationId),
    enabled: Boolean(locationId)
  });

  return {
    site: data?.data,
    loading: isLoading,
    isError,
    isSuccess
  };
};

import { useQuery } from "react-query";
import { PotashReportingApi, SectionPermissionResponse } from "../../..";

export const useGetSectionPermissions = (locationId: string, userId) => {
  const { data, isLoading, isError, isSuccess } = useQuery<SectionPermissionResponse>({
    queryKey: ["section-permission", userId, locationId],
    queryFn: () => PotashReportingApi.Sections.get(locationId, userId),
    enabled: Boolean(locationId && userId)
  });

  return {
    sectionPermissions: data ? data.data : [],
    loading: isLoading,
    isError,
    isSuccess
  };
};

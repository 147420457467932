import { useMutation, useQueryClient } from "react-query";
import { PotashReportingApi, SectionPermission } from "../../..";

export const useAddSectionPermissions = (locationId: string, userId: string) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(
    (sectionPermissions: SectionPermission[]) =>
      PotashReportingApi.Sections.add(sectionPermissions, userId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["section-permission", userId, locationId]);
      }
    }
  );

  return {
    permissions: data,
    addSectionPermissions: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};

import { useQuery } from "react-query";
import { PotashReportingApi, TabPermissionResponse } from "../../..";

export const useGetUserPermissions = (locationId: string, userId) => {
  const { data, isLoading, isError, isSuccess } = useQuery<TabPermissionResponse>({
    queryKey: ["tab-permission", userId, locationId],
    queryFn: () => PotashReportingApi.Tabs.get(locationId, userId),
    enabled: Boolean(locationId && userId)
  });

  return {
    permissions: data ? data.data : [],
    loading: isLoading,
    isError,
    isSuccess
  };
};

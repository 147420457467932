import { useMutation, useQueryClient } from "react-query";
import { PotashReportingApi, ReportInstanceResponse, ReportInstance } from "../../..";

export const useUpdateReportInstance = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(
    (reportInstance: ReportInstance) =>
      PotashReportingApi.ReportComments.updateReportInstance(reportInstance),
    {
      onSuccess: (reportInstance) => {
        queryClient.setQueryData<ReportInstanceResponse | undefined>(
          ["report-instance"],
          (currentState: ReportInstanceResponse | undefined) => {
            if (!currentState) return undefined;

            const data = reportInstance.data;

            return {
              ...currentState,
              data: data
            };
          }
        );
      }
    }
  );

  return {
    report: data,
    updateReportInstance: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};

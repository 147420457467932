import { HTTPError } from "ky";

export /**
 * Extract error message from ky HTTPError object
 *
 * @param {HTTPError} error
 * @param {string} [defaultErrorMessage="There was a problem with your request, please try again."]
 * @return {*}  {(Promise<string | undefined>)}
 */
const extractHttpErrorMessage = async (
  error: HTTPError,
  defaultErrorMessage = "There was a problem with your request, please try again."
): Promise<string | undefined> => {
  if (!error) {
    return undefined;
  }

  let errorString = defaultErrorMessage;

  try {
    const responseBody = await error.response.json();
    errorString = responseBody?.error;
  } catch {
    const text = await error.response?.text();

    if (text) {
      errorString = `${errorString}: ${text}`;
    }
  }

  error.message = errorString;
};

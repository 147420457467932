import { useQuery } from "react-query";
import { PotashReportingApi, CommentsResponse } from "../../..";

export const useGetReportComments = (
  locationId: string,
  reportTypeId: number,
  startDate: string
) => {
  const { data, isLoading, isError, isSuccess } = useQuery<CommentsResponse>({
    queryKey: ["report-comments", locationId, startDate],
    queryFn: () => PotashReportingApi.ReportComments.get(locationId, reportTypeId, startDate),
    enabled: reportTypeId > 0 && Boolean(locationId && startDate)
  });

  return {
    comments: data ? data.data : [],
    loading: isLoading,
    isError,
    isSuccess
  };
};

import { useQuery } from "react-query";
import { PotashReportingApi, IncidentDocumentResponse } from "../../..";

export const useGetIncidentDetails = (locationId: string, startDate: string) => {
  const { data, isLoading, isError, isSuccess } = useQuery<IncidentDocumentResponse>({
    queryKey: ["incident-details", locationId, startDate],
    queryFn: () => PotashReportingApi.ReportComments.getIncidentDetails(locationId, startDate),
    enabled: Boolean(locationId && startDate)
  });

  return {
    incidents: data ? data.data : "",
    loading: isLoading,
    isError,
    isSuccess
  };
};

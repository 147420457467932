import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { lazy } from "react";
import { SpinningLoader } from "@packages/theme-mui-v5";
import ApplicationAccessManagement from "@packages/application-access-management";
import applicationConfig, { basePath } from "./config";
import Footer from "./components/Footer";

const LandingPage = SpinningLoader(lazy(() => import("./pages/LandingPage")));
const MailingList = SpinningLoader(lazy(() => import("./pages/MailingList")));
const AddUsersToMailingList = SpinningLoader(
  lazy(() => import("./drawers/mailing-list/AddUsersToMailingList"))
);
const RemoveUsersFromMailingList = SpinningLoader(
  lazy(() => import("./drawers/mailing-list/RemoveUsersFromMailingList"))
);
const AddSubscription = SpinningLoader(
  lazy(() => import("./drawers/site-mailing-list/AddUsersToSiteMailingList"))
);
const RemoveSubscription = SpinningLoader(
  lazy(() => import("./drawers/site-mailing-list/RemoveUsersFromSiteMailingList"))
);

const SiteMailingList = SpinningLoader(lazy(() => import("./pages/SiteMailingList")));
const EditorConfig = SpinningLoader(lazy(() => import("./pages/EditorConfig")));
const ApproverConfig = SpinningLoader(lazy(() => import("./pages/ApproverConfig")));

const DowntimeReports = SpinningLoader(lazy(() => import("./pages/DowntimeReports")));
const WeeklyHighlightReport = SpinningLoader(lazy(() => import("./pages/WeeklyHighlightReport")));
const WeeklyReportEditor = SpinningLoader(lazy(() => import("./pages/WeeklyReportEditor")));
const MineDelayKPIs = SpinningLoader(lazy(() => import("./pages/MineDelayKPIs")));

const Router = () => {
  return (
    <BrowserRouter basename={basePath}>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="downtime-reports" element={<DowntimeReports />} />
        <Route path="report-distribution/corporate-mailing-list" element={<MailingList />}>
          <Route path="add" element={<AddUsersToMailingList />} />
          <Route path=":reportType/user/:emailId/delete" element={<RemoveUsersFromMailingList />} />
        </Route>
        <Route path="report-manager/mailing-list" element={<SiteMailingList />}>
          <Route path="add" element={<AddSubscription />} />
          <Route
            path=":reportType/user/:emailId/locationId/:locationId/delete"
            element={<RemoveSubscription />}
          />
        </Route>
        <Route path="report-manager/manage-editors" element={<EditorConfig />}></Route>
        <Route path="report-manager/manage-approvers" element={<ApproverConfig />}></Route>
        <Route path="submit-data/weekly-highlight-report" element={<WeeklyReportEditor />}></Route>
        <Route path="weekly-highlight-report" element={<WeeklyHighlightReport />}></Route>
        <Route path="mine-delay-reports/mine-delay-KPIs" element={<MineDelayKPIs />}></Route>
        <Route
          path="admin/*"
          element={
            <ApplicationAccessManagement
              basePath={basePath}
              applicationId={applicationConfig.applicationId}
            />
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default Router;

import { Paper } from "@mui/material";

interface Props {
  children: React.ReactNode;
}

export const FormControlsContainer = (props: Props) => {
  const { children } = props;
  return <Paper sx={{ mt: 2 }}>{children}</Paper>;
};

import { useQuery } from "react-query";
import { PotashReportingApi, ReportSubscriptionsResponse, QueryOptions } from "../../..";

export const useGetMailingList = (
  locationId: string,
  reportTypeID: number,
  query?: QueryOptions
) => {
  const { data, isLoading, isError, isSuccess } = useQuery<ReportSubscriptionsResponse>({
    queryKey: ["report-subscription", locationId, reportTypeID],
    queryFn: () =>
      PotashReportingApi.ReportSubscriptions.getReportSubscriptions(
        locationId,
        reportTypeID,
        query
      ),
    enabled: reportTypeID > 0 && Boolean(locationId)
  });

  return {
    users: data ? data.data : [],
    loading: isLoading,
    isError,
    isSuccess
  };
};
